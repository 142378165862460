import React from 'react';
import './Title.css';

function Title() {
  return (
    <div className="title-cont">
      <h1>Genial</h1>
      <h2>Website Developers</h2>
    </div>
  );
}

export default Title;
