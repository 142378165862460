const clients = [
  {
    id: 1,
    img: '/images/clients-gallery/genial-photography.jpg',
    heading: "Genial Photography",
    text: "Our standalone website showcasing some of our photography work",
    url: "https://www.genialphotography.co.uk/"
  },
  {
    id: 3,
    img: '/images/clients-gallery/marylebone-cars.jpg',
    heading: "Marylebone Cars",
    text: "Used car dealership based in central London specialising in quality low mileage vehicles",
    url: "https://www.marylebonecarcentre.co.uk/"
  },
  {
    id: 5,
    img: '/images/clients-gallery/john-warren-cars.jpg',
    heading: "John Warren Cars",
    text: "A BMW Specialist based in Stevenage and Aylesbury – clients of Genial since 1998",
    url: "https://www.independentbmw.co.uk/"
  },
  {
    id: 6,
    img: '/images/clients-gallery/1st-line-motorcycles.jpg',
    heading: "1st Line Motorcycles",
    text: "A Honda main dealer - we have been looking after their web presence since 1998",
    url: "https://www.1stlinemotorcycles.co.uk/"
  }
];

export default clients;